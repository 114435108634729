import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = 'https://www.sosohajainterior.com';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

const ImageGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [translatedPrompt, setTranslatedPrompt] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [error, setError] = useState(null);
  const [translationError, setTranslationError] = useState(null);
  const [strength, setStrength] = useState(0.75);
  const [model, setModel] = useState('sd3-large-turbo');
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const savedGallery = JSON.parse(localStorage.getItem('imageGallery')) || [];
    setGallery(savedGallery);
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUploadedImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handlePromptChange = async (e) => {
    const koreanPrompt = e.target.value;
    setPrompt(koreanPrompt);
    setTranslationError(null);

    if (koreanPrompt.trim() === '') {
      setTranslatedPrompt('');
      setIsTranslating(false);
      return;
    }

    setIsTranslating(true);

    try {
      const response = await api.post('/api/translate', { text: koreanPrompt });
      console.log('Translation response:', response);
      
      if (response.data && response.data.translatedText) {
        setTranslatedPrompt(response.data.translatedText);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Translation error:', error.response || error);
      setTranslationError(
        error.response?.data?.error || 
        error.message || 
        'Translation failed. Please try again.'
      );
      setTranslatedPrompt('');
    } finally {
      setIsTranslating(false);
    }
  };

  const handleGenerate = async () => {
    if (!uploadedImage) {
      setError('Please upload an image');
      return;
    }
    if (isTranslating) {
      setError('Please wait for the translation to complete');
      return;
    }
    setIsLoading(true);
    setError(null);
    setGeneratedImage(null);

    try {
      const response = await api.post('/api/generate-image', {
        image: uploadedImage,
        prompt: translatedPrompt || prompt,
        strength,
        model
      }, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Image generation response:', response);

      const blob = new Blob([response.data], { type: 'image/png' });
      const imageUrl = URL.createObjectURL(blob);
      setGeneratedImage(imageUrl);

      const newGallery = [...gallery, { id: Date.now(), url: imageUrl, prompt: translatedPrompt || prompt }];
      setGallery(newGallery);
      localStorage.setItem('imageGallery', JSON.stringify(newGallery));
    } catch (error) {
      console.error('Image generation error:', error.response || error);
      setError('Failed to generate image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <h1 className="text-2xl font-semibold mb-6">AI Image Generator</h1>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="space-y-4">
              <input
                type="text"
                className="w-full p-2 border rounded"
                placeholder="한글로 이미지 설명 입력"
                value={prompt}
                onChange={handlePromptChange}
              />
              {isTranslating && (
                <div className="text-blue-500 text-sm">Translating...</div>
              )}
              {translationError && (
                <div className="text-red-500 text-sm">{translationError}</div>
              )}
              {translatedPrompt && !isTranslating && (
                <div className="text-sm text-gray-600">
                  영어 번역: {translatedPrompt}
                </div>
              )}
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
                {uploadedImage && (
                  <img src={uploadedImage} alt="Uploaded" className="mt-2 w-full rounded-lg shadow-md" />
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Strength: {strength.toFixed(2)}</label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={strength}
                  onChange={(e) => setStrength(parseFloat(e.target.value))}
                  className="w-full"
                />
              </div>
              <select
                value={model}
                onChange={(e) => setModel(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="sd3-large-turbo">SD3 Large Turbo</option>
                <option value="sd3-medium">SD3 Medium</option>
                <option value="sd3-large">SD3 Large</option>
              </select>
              <button
                onClick={handleGenerate}
                disabled={!uploadedImage || isLoading || isTranslating}
                className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Generating...' : 'Generate Image'}
              </button>
            </div>
            {generatedImage && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold mb-2">Generated Image</h2>
                <img src={generatedImage} alt="Generated" className="w-full rounded-lg shadow-md" />
              </div>
            )}
            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Gallery</h2>
              <div className="grid grid-cols-2 gap-4">
                {gallery.map((item) => (
                  <div key={item.id} className="relative">
                    <img src={item.url} alt={item.prompt} className="w-full h-32 object-cover rounded-lg shadow-md" />
                    <p className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs p-1 truncate">
                      {item.prompt}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGenerator;
